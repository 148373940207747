import { Fragment } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import { ReelsSection } from 'src/core/contentManager/entities';
import YoutubeVideo from 'src/core/common/components/common/YoutubeVideo';
import ContentManagerSection from 'src/core/contentManager/components/organisms/ContentManagerSection';
import { useInView } from 'src/core/common/hooks';
import Control from 'src/core/common/components/common/CarouselButton';
import Image from 'src/core/common/components/common/Image';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './SectionReels.module.scss';

const LazyCarousel = dynamic(() => import('src/core/common/components/common/Carousel'));

type SectionReelsProps = {
  className?: string;
  sectionContent: ReelsSection;
  isLazy?: boolean;
};

const SLIDES_TO_SCROLL = 1;
const SLIDES_TO_SHOW_DEFAULT = 4;
const SLIDES_TO_SHOW_BY_BREAKPOINT = [
  { breakpoint: 768, slidesToShow: 2.4 },
  { breakpoint: 480, slidesToShow: 1.3 },
] as const;

const IN_VIEW_ROOT_MARGIN = '750px 0px';

const SectionReels = ({ className = '', sectionContent, isLazy = true }: SectionReelsProps) => {
  const settings = {
    prevArrow: (
      <Control classNames={cn(styles.sliderButton, styles.prevButton)}>
        <Icon name="arrow" className={styles.arrow} />
      </Control>
    ),
    slidesToScroll: SLIDES_TO_SCROLL,
    nextArrow: (
      <Control classNames={cn(styles.sliderButton, styles.nextButton)}>
        <Icon name="arrow" className={styles.arrow} />
      </Control>
    ),
    slidesToShow: SLIDES_TO_SHOW_DEFAULT,
    arrows: true,
    infinite: false,
    className: styles.slider,
    responsive: SLIDES_TO_SHOW_BY_BREAKPOINT.map((options) => ({
      breakpoint: options.breakpoint,
      settings: {
        slidesToShow: options.slidesToShow,
      },
    })),
  };

  const { title } = sectionContent;

  const { ref, inView } = useInView({
    threshold: 0,
    skip: !isLazy,
    triggerOnce: true,
    rootMargin: IN_VIEW_ROOT_MARGIN,
    fallbackInView: true,
  });

  const shouldBeInView = !isLazy || inView;

  return (
    <ContentManagerSection ref={ref} className={className} contentClassName={styles.content}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {shouldBeInView && (
        <LazyCarousel {...settings}>
          {sectionContent.items.map((reel) => (
            <Fragment key={reel.id}>
              <YoutubeVideo
                classNames={{ root: styles.slide, playButton: styles.playButton }}
                title={reel.videoAlt}
                id={reel.videoId}
              />
              <p className={styles.author}>
                <Image
                  type="future"
                  src={reel.image.url}
                  alt={reel.image.alt}
                  width={reel.image.width}
                  height={reel.image.height}
                  className={styles.authorImage}
                />
                <span className={styles.authorName}>{reel.name}</span>
              </p>
            </Fragment>
          ))}
        </LazyCarousel>
      )}
    </ContentManagerSection>
  );
};

export default SectionReels;
