import cn from 'classnames';
import { Expert, ExpertId } from 'src/core/experts/entities';
import ExpertCard from 'src/core/contentManager/components/ExpertCard';
import { TextButton } from 'src/core/common/components/common/Button';
import Image from 'src/core/common/components/common/Image';
import { ExpertsSection } from 'src/home/entities';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './SectionExperts.module.scss';

export type SectionExpertsProps = {
  sectionContent: ExpertsSection;
  className?: string;
  experts: Array<Expert>;
  onStartChat: (expertId: ExpertId) => void;
  onExpertSelected: (expertId: ExpertId) => void;
  onSeeMoreExperts: () => void;
};

const SectionExperts = ({
  sectionContent,
  className,
  experts,
  onExpertSelected,
  onStartChat,
  onSeeMoreExperts,
}: SectionExpertsProps) => {
  const { title, description, button } = sectionContent;

  return (
    <section className={cn(styles.root, className)} id={sectionContent.component}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{description}</p>
        <div className={styles.expertListWrapper}>
          <ul className={styles.expertList}>
            {experts.map((expert) => (
              <li key={expert.id} className={styles.expertListItem}>
                <ExpertCard
                  className={styles.expert}
                  onStartChat={() => onStartChat(expert.id)}
                  onExpertClick={() => onExpertSelected(expert.id)}
                  info={expert}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.more}>
          <Image
            type="future"
            className={styles.moreExperts}
            height={sectionContent.image.height}
            width={sectionContent.image.width}
            src={sectionContent.image.url}
            alt={sectionContent.image.alt}
          />
          <TextButton
            afterIcon={<Icon name="arrow" className={styles.icon} />}
            wrapperClassName={styles.moreControlWrapper}
            className={styles.moreControl}
            iconClass={styles.moreControlIcon}
            onClick={onSeeMoreExperts}
            data-testid="see-more-advisors-button"
          >
            {button.text}
          </TextButton>
        </div>
      </div>
    </section>
  );
};

export default SectionExperts;
