import ContentManagerLayout from 'src/core/contentManager/components/templates/ContentManagerLayout';
import ThemeProvider from 'src/core/theme/context';
import { HomePage as HomePageEntity } from 'src/home/entities';
import { ContentManagerBanner } from 'src/core/contentManager/components/organisms/ContentManagerBanner';
import { useDefaultContentManagerBanner } from 'src/core/contentManager/hooks/useDefaultContentManagerBanner';
import DefaultContentManagerBannerText from 'src/core/contentManager/components/atoms/DefaultContentManagerBannerText';
import Home from '../../templates/Home';
import styles from './HomePage.module.scss';

export type HomePageProps = HomePageEntity;

const HomePage = ({ metaData, sections, header, footer }: HomePageProps) => {
  const { getBannerProps } = useDefaultContentManagerBanner();

  const renderBannerText = () => <DefaultContentManagerBannerText />;
  return (
    <ThemeProvider>
      <div className={styles.root}>
        <ContentManagerLayout
          metaData={metaData}
          header={header}
          footer={footer}
          banner={<ContentManagerBanner {...getBannerProps()} renderText={renderBannerText} />}
        >
          <Home sections={sections} />
        </ContentManagerLayout>
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
