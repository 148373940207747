import { useEffect } from 'react';
import { ExpertId } from 'src/core/experts/entities';
import { useExpertsCatalogueModalState, useRouter, useServices } from 'src/core/common/hooks';
import { BASE_ROUTES } from 'src/core/common/constants/routes';
import { useExperts } from 'src/core/experts/store/queries';
import { useLastSeenModalContext } from 'src/core/contentManager/hooks';
import {
  HomePageAppBannerClickEvent,
  HomePageExpertCardClickEvent,
  HomePageExpertStartChatClickEvent,
  HomePageGetStartedClickEvent,
  HomePageOpenEvent,
  HomePageSeeMoreExpertsClickEvent,
} from '../analytics/events';
import { HOMEPAGE_GET_EXPERTS_OPTIONS } from '../constants';

export function useHome() {
  const { cancel: cancelLastSeenModal } = useLastSeenModalContext();

  const router = useRouter();
  const { analyticsService } = useServices();
  const { experts } = useExperts({ ...HOMEPAGE_GET_EXPERTS_OPTIONS });
  const { modalOpened, selectedExpertId, closeModal, openModal } = useExpertsCatalogueModalState();

  useEffect(() => {
    analyticsService.track(new HomePageOpenEvent());
  }, [analyticsService]);

  const selectExpert = (id: ExpertId) => {
    cancelLastSeenModal();
    analyticsService.track(new HomePageExpertCardClickEvent());
    openModal(id);
  };

  const startChat = (id: ExpertId) => {
    cancelLastSeenModal();
    analyticsService.track(new HomePageExpertStartChatClickEvent());
    openModal(id);
  };

  const getStarted = () => {
    analyticsService.track(new HomePageGetStartedClickEvent());

    router.push(BASE_ROUTES.SIMPLE_SIMPLE_REGISTRATION_QUIZ_GENDER);
  };

  const seeMoreExperts = () => {
    analyticsService.track(new HomePageSeeMoreExpertsClickEvent());

    router.push(BASE_ROUTES.SIMPLE_SIMPLE_REGISTRATION_QUIZ_GENDER);
  };

  const selectAppBanner = () => {
    analyticsService.track(new HomePageAppBannerClickEvent());
  };

  return {
    selectExpert,
    startChat,
    getStarted,
    seeMoreExperts,
    selectAppBanner,
    experts,
    closeModal,
    modalOpened,
    selectedExpertId,
  };
}
