import cn from 'classnames';
import Button from 'src/core/common/components/common/Button';
import Image from 'src/core/common/components/common/Image';
import { StartSection } from 'src/home/entities';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './SectionGetStarted.module.scss';

export type SectionGetStartedProps = {
  className?: string;
  onGetStarted: () => void;
  sectionContent: StartSection;
};

const SectionGetStarted = ({ className, onGetStarted, sectionContent }: SectionGetStartedProps) => {
  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <span className={styles.titleStart}>{sectionContent.titleStart}</span>{' '}
          <br className={styles.desktopBreak} />
          <span className={styles.titleEnd}>{sectionContent.titleEnd}</span>
        </h1>
        <p className={styles.text}>{sectionContent.description}</p>
        <Button
          wrapperClassName={styles.buttonWrapper}
          afterIcon={<Icon name="arrow" className={styles.icon} />}
          className={styles.button}
          iconClass={styles.buttonIcon}
          labelClassName={styles.buttonLabel}
          onClick={onGetStarted}
          data-testid="get-started-button"
        >
          {sectionContent.button.text}
        </Button>
      </div>
      <Image
        type="future"
        priority
        className={cn(styles.illustration, styles.illustrationMobile)}
        src={sectionContent.mobileImage.url}
        alt={sectionContent.mobileImage.alt}
        height={sectionContent.mobileImage.height}
        width={sectionContent.mobileImage.width}
      />
      <Image
        type="future"
        priority
        className={cn(styles.illustration, styles.illustrationDesktop)}
        src={sectionContent.desktopImage.url}
        alt={sectionContent.desktopImage.alt}
        height={sectionContent.desktopImage.height}
        width={sectionContent.desktopImage.width}
      />
    </section>
  );
};

export default SectionGetStarted;
