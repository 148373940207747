import cn from 'classnames';
import Image from 'src/core/common/components/common/Image';
import { BonusesSection } from 'src/core/contentManager/entities';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './SectionBonuses.module.scss';

type SectionBonusesProps = {
  sectionContent: BonusesSection;
  className?: string;
};

const SectionBonuses = ({ sectionContent, className }: SectionBonusesProps) => (
  <section className={cn(styles.root, className)}>
    <div className={styles.content}>
      <ul className={styles.bonusesList}>
        {sectionContent.bonusesItems.map((item) => (
          <li key={item.id} className={styles.bonusesItem}>
            <div className={styles.bonusesItemInner}>
              <Image
                type="future"
                className={cn(styles.itemImage, styles.itemImageDesktop)}
                src={item.imageDesktop.url}
                width={item.imageDesktop.width}
                height={item.imageDesktop.height}
                alt={item.imageDesktop.alt}
              />
              <Image
                type="future"
                className={cn(styles.itemImage, styles.itemImageMobile)}
                src={item.imageMobile.url}
                width={item.imageMobile.width}
                height={item.imageMobile.height}
                alt={item.imageMobile.alt}
              />
              <div className={styles.itemContent}>
                <p className={styles.itemTitle}>{item.title}</p>
                <p className={styles.itemSubtitle}>{item.description}</p>
              </div>
            </div>
          </li>
        ))}

        <li className={cn(styles.bonusesItem, styles.rewardsItem)}>
          <div className={styles.bonusesItemInner}>
            <Image
              type="future"
              className={cn(styles.itemImage, styles.itemImageDesktop)}
              src={sectionContent.rewardsImageMobile.url}
              alt={sectionContent.rewardsImageMobile.alt}
              width={sectionContent.rewardsImageMobile.width}
              height={sectionContent.rewardsImageMobile.height}
            />
            <Image
              type="future"
              className={cn(styles.itemImage, styles.itemImageMobile)}
              src={sectionContent.rewardsImageDesktop.url}
              alt={sectionContent.rewardsImageDesktop.alt}
              width={sectionContent.rewardsImageDesktop.width}
              height={sectionContent.rewardsImageDesktop.height}
            />
            <div className={styles.itemContent}>
              <p className={styles.itemTitle}>{sectionContent.rewardsTitle}</p>
              <p className={styles.itemSubtitle}>{sectionContent.rewardsDescription}</p>
              <div className={styles.divider}></div>
              <ul className={styles.perksList}>
                {sectionContent.rewardsItems.map((item) => (
                  <li key={item.id} className={styles.perksItem}>
                    <Icon name="star-five-points" className={styles.icon} />
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
);

export default SectionBonuses;
