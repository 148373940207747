import { GetStaticProps } from 'next';
import { isBuildPhase } from 'src/core/common/utils/isBuildPhase';
import { HomePageProps } from 'src/home/ui/pages/HomePage';
import { config } from 'src/config';
import { HOMEPAGE_GET_EXPERTS_OPTIONS } from 'src/home/constants';
import { HomeService } from 'src/home/services';
import { ExpertServiceBuilder } from 'src/core/experts/services/ExpertServiceBuilder';
import { normalizeError } from 'src/core/common/utils/errors';
import { UnableToLoadPageDataError } from 'src/core/common/errors';
import { createContentManagerHttpClient } from 'src/core/common/services';
import { QueryCacheFallback } from 'src/core/queryCache/entities';
import { BUILD_GET_EXPERTS_PARAMS } from 'src/core/experts/constants';
import { LoggerBuilder } from 'src/core/common/logger/services/LoggerBuilder';
import { ExpertsRequestParams } from 'src/core/experts/entities';
import { pickRandomNElements } from 'src/core/common/utils/array/pickRandomNElements';

export { default } from 'src/home/ui/pages/HomePage';

export const getStaticProps: GetStaticProps<HomePageProps & QueryCacheFallback> = async () => {
  const isBuild = isBuildPhase();

  const homeService = new HomeService(createContentManagerHttpClient());
  const logger = new LoggerBuilder().addConsoleTransport().getResult();
  const expertServiceBuilder = new ExpertServiceBuilder();

  const getExpertsParams: ExpertsRequestParams = isBuild
    ? BUILD_GET_EXPERTS_PARAMS.params
    : HOMEPAGE_GET_EXPERTS_OPTIONS.params;

  if (isBuild) {
    expertServiceBuilder.addCaching();
  }

  const expertService = expertServiceBuilder.getResult();

  try {
    const [{ metaData, sections, footer, header }, experts] = await Promise.all([
      homeService.getHomePage(),
      expertService.getExperts(getExpertsParams),
    ]);

    return {
      props: {
        metaData,
        sections,
        header,
        footer,
        fallback: {
          [HOMEPAGE_GET_EXPERTS_OPTIONS.key]: isBuild
            ? pickRandomNElements(experts, HOMEPAGE_GET_EXPERTS_OPTIONS.params.perPage)
            : experts,
        },
      },
      revalidate: config.pageRevalidationIntervalsSeconds.default,
    };
  } catch (e) {
    const error = normalizeError(e);
    const errorInstance = new UnableToLoadPageDataError(error.message);
    logger.error(errorInstance);
    throw errorInstance;
  }
};
