import dynamic from 'next/dynamic';
import { ContentManagerSectionsType } from 'src/core/contentManager/entities';
import { FlowName } from 'src/core/quiz/entities';
import { HomeSections, HomeSectionType } from 'src/home/entities';
import { useHome } from 'src/home/hooks/useHome';
import { getSectionKey } from 'src/core/contentManager/utils/getSectionKey';
import SectionHow from 'src/core/contentManager/components/sections/SectionHow';
import SectionBonuses from 'src/core/contentManager/components/sections/SectionBonuses';
import SectionApplicationAdvantages from 'src/core/contentManager/components/sections/SectionApplicationAdvantages';
import SectionReviews from 'src/core/contentManager/components/sections/SectionReviews';
import SectionStats from 'src/core/contentManager/components/sections/SectionStats';
import SectionSeoText from 'src/core/contentManager/components/sections/SectionSeoText';
import SectionTopics from 'src/core/contentManager/components/sections/SectionTopics';
import SectionVideo from 'src/core/contentManager/components/organisms/SectionVideo';
import SectionFaq from 'src/core/contentManager/components/sections/SectionFaq';
import SectionReels from 'src/core/contentManager/components/organisms/SectionReels';
import SectionApps from '../../organisms/SectionApps';
import SectionExperts from '../../organisms/SectionExperts';
import SectionFeaturing from '../../organisms/SectionFeaturing';
import SectionGetStarted from '../../organisms/SectionGetStarted';
import SectionAdvantages from '../../organisms/SectionAdvantages';
import styles from './Home.module.scss';

const LazyExpertsCatalogueModal = dynamic(
  () => import('src/core/common/components/common/ExpertsCatalogueModal'),
  {
    ssr: false,
  },
);

type HomeProps = {
  sections: HomeSections;
};

const Home = ({ sections }: HomeProps) => {
  const {
    startChat,
    getStarted,
    seeMoreExperts,
    selectExpert,
    selectAppBanner,
    experts,
    modalOpened,
    closeModal,
    selectedExpertId,
  } = useHome();

  return (
    <>
      {sections.map((section) => {
        switch (section.component) {
          case HomeSectionType.START_SECTION:
            return (
              <SectionGetStarted
                key={getSectionKey(section)}
                sectionContent={section}
                onGetStarted={getStarted}
              />
            );
          case HomeSectionType.APPS_SECTION: {
            return (
              <SectionApps
                key={getSectionKey(section)}
                sectionContent={section}
                onSelect={selectAppBanner}
              />
            );
          }
          case ContentManagerSectionsType.STATS_SECTION:
            return (
              <SectionStats
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case HomeSectionType.FEATURING_SECTION:
            return (
              <SectionFeaturing
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case ContentManagerSectionsType.TOPIC_SECTION:
            return (
              <SectionTopics
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case HomeSectionType.EXPERTS_SECTION:
            return (
              <SectionExperts
                key={getSectionKey(section)}
                sectionContent={section}
                onExpertSelected={selectExpert}
                experts={experts}
                onStartChat={startChat}
                className={styles.section}
                onSeeMoreExperts={seeMoreExperts}
              />
            );
          case ContentManagerSectionsType.VIDEO_SECTION:
            return (
              <SectionVideo
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case ContentManagerSectionsType.REELS_SECTION:
            return (
              <SectionReels
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case ContentManagerSectionsType.REVIEW_SECTION:
            return (
              <SectionReviews
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case ContentManagerSectionsType.APPLICATION_ADVANTAGES_SECTION:
            return (
              <SectionApplicationAdvantages
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case ContentManagerSectionsType.BONUSES_SECTION:
            return (
              <SectionBonuses
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case ContentManagerSectionsType.HOW_SECTION:
            return (
              <SectionHow
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
                onGetStarted={getStarted}
              />
            );
          case HomeSectionType.ADVANTAGES_SECTION:
            return (
              <SectionAdvantages
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case ContentManagerSectionsType.SEO_TEXT_SECTION:
            return (
              <SectionSeoText
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case ContentManagerSectionsType.FAQ_SECTION:
            return (
              <SectionFaq
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          default:
            return null;
        }
      })}
      {modalOpened && (
        <LazyExpertsCatalogueModal
          onClose={closeModal}
          flowName={FlowName.H0ME}
          selectedExpertId={selectedExpertId}
          isOpen={modalOpened}
        />
      )}
    </>
  );
};

export default Home;
