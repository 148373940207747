import cn from 'classnames';
import Image from 'src/core/common/components/common/Image';
import { AdvantagesSection } from 'src/home/entities';
import styles from './SectionAdvantages.module.scss';

export type SectionAdvantagesProps = {
  sectionContent: AdvantagesSection;
  className?: string;
};

const SectionAdvantages = ({ className, sectionContent }: SectionAdvantagesProps) => {
  return (
    <section className={cn(styles.root, className)} id={sectionContent.component}>
      <div className={styles.content}>
        <div className={styles.description}>
          <h2 className={styles.title}>{sectionContent.title}</h2>
          <p className={styles.text}>{sectionContent.description}</p>
        </div>
        <ul className={styles.advantageList}>
          {sectionContent.advantagesList.map(({ text, icon, id }) => (
            <li key={id} className={styles.advantageListItem}>
              <div className={styles.advantage}>
                <Image
                  type="future"
                  className={styles.advantageIcon}
                  src={icon.url}
                  width={icon.width}
                  height={icon.height}
                  alt={icon.alt}
                />
                <p className={styles.advantageText}>{text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SectionAdvantages;
