import cn from 'classnames';
import { MouseEventHandler } from 'react';
import { ReactComponent as AppleIcon } from 'public/images/apple.svg';
import { ReactComponent as AndroidIcon } from 'public/images/android.svg';
import Link from 'src/core/common/components/common/Link';
import { BASE_ROUTES } from 'src/core/common/constants/routes';
import { prepareHtml } from 'src/core/common/utils/prepareHtml';
import { AppsSection } from 'src/home/entities';
import styles from './SectionApps.module.scss';

export type SectionAppsProps = {
  className?: string;
  sectionContent: AppsSection;
  onSelect: () => void;
};

const SectionApps = ({ className, sectionContent, onSelect }: SectionAppsProps) => {
  const selectBanner: MouseEventHandler<HTMLAnchorElement> = () => {
    onSelect();
  };
  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.container}>
        <h3 className={styles.title} dangerouslySetInnerHTML={prepareHtml(sectionContent.text)} />
        <ul className={styles.appList}>
          <li className={styles.appListItem}>
            <AppleIcon className={styles.appIcon} />
          </li>
          <li className={cn(styles.appListItem, styles.appListItemDivider)}>
            <span className={styles.appDivider}>&</span>
          </li>
          <li className={styles.appListItem}>
            <AndroidIcon className={styles.appIcon} />
          </li>
        </ul>
      </div>
      <Link
        href={BASE_ROUTES.NEBULA_APP_MOBILE}
        className={styles.sectionLink}
        aria-label="Go to Nebula App Mobile page"
        onClick={selectBanner}
      />
    </section>
  );
};

export default SectionApps;
