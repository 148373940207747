import cn from 'classnames';
import Button from 'src/core/common/components/common/Button';
import { HowSection } from 'src/core/contentManager/entities';
import styles from './SectionHow.module.scss';

export type SectionHowProps = {
  sectionContent: HowSection;
  className?: string;
  onGetStarted: () => void;
};

const SectionHow = ({ className, onGetStarted, sectionContent }: SectionHowProps) => {
  const { title, howItems, button } = sectionContent;

  return (
    <section className={cn(styles.root, className)} id={sectionContent.component}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.answerListWrapper}>
          <ul className={styles.answerList}>
            {howItems.map(({ id, description, title: questionTitle }) => (
              <li key={id} className={styles.answerListItem}>
                <div className={styles.answer}>
                  <h4 className={styles.answerTitle}>{questionTitle}</h4>
                  <p className={styles.answerText}>{description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <Button
          onClick={onGetStarted}
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
        >
          {button.text}
        </Button>
      </div>
    </section>
  );
};

export default SectionHow;
