import {
  ContentManagerButton,
  ContentManagerImage,
  ApplicationAdvantagesSection,
  BonusesSection,
  FaqSection,
  HowSection,
  ReviewSection,
  SeoTextSection,
  StatsSection,
  TopicSection,
  ContentManagerMetaData,
  ContentManagerHeader,
  ContentManagerFooter,
  VideoSection,
  ReelsSection,
} from 'src/core/contentManager/entities';

export enum HomeSectionType {
  START_SECTION = 'home-page.start-section',
  APPS_SECTION = 'home-page.apps-section',
  EXPERTS_SECTION = 'home-page.experts-section',
  FEATURING_SECTION = 'home-page.featuring-section',
  ADVANTAGES_SECTION = 'home-page.advantages-section',
}

type BasicSection = {
  id: number;
  component: HomeSectionType;
};

export type StartSection = BasicSection & {
  button: ContentManagerButton;
  mobileImage: ContentManagerImage;
  component: HomeSectionType.START_SECTION;
  desktopImage: ContentManagerImage;
  description: string;
  titleStart: string;
  titleEnd: string;
};

export type AppsSection = BasicSection & {
  text: string;
  component: HomeSectionType.APPS_SECTION;
};

export type FeaturingSection = BasicSection & {
  title: string;
  imageDesktop: ContentManagerImage;
  imageMobile: ContentManagerImage;
  component: HomeSectionType.FEATURING_SECTION;
};

export type ExpertsSection = BasicSection & {
  title: string;
  component: HomeSectionType.EXPERTS_SECTION;
  description: string;
  button: ContentManagerButton;
  image: ContentManagerImage;
};

export type AdvantagesSectionItem = {
  icon: ContentManagerImage;
  id: number;
  text: string;
};

export type AdvantagesSection = BasicSection & {
  advantagesList: Array<AdvantagesSectionItem>;
  description: string;
  title: string;
  component: HomeSectionType.ADVANTAGES_SECTION;
};

export type HomeSectionsUnion =
  | StartSection
  | AppsSection
  | StatsSection
  | FeaturingSection
  | TopicSection
  | ExpertsSection
  | ReviewSection
  | ApplicationAdvantagesSection
  | HowSection
  | BonusesSection
  | AdvantagesSection
  | SeoTextSection
  | FaqSection
  | ReelsSection
  | VideoSection;

export type HomeSections = Array<HomeSectionsUnion>;

export type HomePage = {
  metaData: ContentManagerMetaData;
  sections: HomeSections;
  header: ContentManagerHeader;
  footer: ContentManagerFooter;
};
