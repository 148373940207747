import cn from 'classnames';
import Image from 'src/core/common/components/common/Image';
import { FeaturingSection } from 'src/home/entities';

import styles from './SectionFeaturing.module.scss';

export type SectionFeaturingProps = {
  className?: string;
  sectionContent: FeaturingSection;
};

const SectionFeaturing = ({ className, sectionContent }: SectionFeaturingProps) => {
  const { title } = sectionContent;
  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.imageWrapper}>
          <Image
            type="future"
            className={cn(styles.partners, styles.partnersMobile)}
            src={sectionContent.imageMobile.url}
            width={sectionContent.imageMobile.width}
            height={sectionContent.imageMobile.height}
            alt={sectionContent.imageMobile.alt}
          />
          <Image
            type="future"
            className={cn(styles.partners, styles.partnersDesktop)}
            src={sectionContent.imageDesktop.url}
            width={sectionContent.imageDesktop.width}
            height={sectionContent.imageDesktop.height}
            alt={sectionContent.imageDesktop.alt}
          />
        </div>
      </div>
    </section>
  );
};

export default SectionFeaturing;
